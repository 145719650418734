.see_sale_detail_card {
  &_img {
    width: 100%;
    height: 250px;
  }
  &_name {
    color: #000000;
    font-weight: bold;
    font-size: 18pt;
  }

  &_description {
    color: #000000;
    span {
      text-decoration: underline;
      color: #000000;
    }
  }

  &_totalraise {
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    &_txt {
      color: #000000;
      margin-bottom: 0;
      font-size: 18px;
      text-align: center;
    }
  }

  &_date {
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    &_txt {
      color: #000000;
      margin-bottom: 0;
      font-size: 18px;
      text-align: center;
    }
  }

  &_price {
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    &_txt {
      color: #000000;
      margin-bottom: 0;
      font-size: 18px;
      text-align: center;
    }
  }

  &_apr {
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    &_txt {
      color: #000000;
      margin-bottom: 0;
      font-size: 18px;
      text-align: center;
    }
  }

  &_date {
    color: #000000;
  }

  &_kyc {
    color: #000000;
  }

  &_amount {
    height: 50px;
    border-radius: 40px;

    &::placeholder {
      color: black;
      font-weight: bolder;
      font-size: 18px;
    }
  }

  &_terms {
    color: #000000;
  }

  &_buy {
    height: 45px;
    padding: 0 25px;
    background-color: var(--button_color);
    color: var(--button_txt_color);
    border: 1px solid var(--button_border_color);
    border-radius: 40px;
    font-size: 15px;
    font-weight: bold;

    &_crypto {
      height: 45px;
      padding: 0 25px;
      background-color: var(--button_txt_color);
      color: var(--button_color);
      border: 1px solid var(--button_border_color);
      border-radius: 40px;
      font-size: 15px;
      font-weight: bold;
    }

    &_disabled {
      cursor: default !important;
      height: 45px;
      padding: 0 25px;
      border: 0;
      border-radius: 40px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      background: linear-gradient(
        180deg,
        rgba(92, 92, 92, 0.4) 0%,
        rgba(183, 183, 183, 0.4) 100%
      );
    }
  }
}

.invest_txt {
  color: #000000;
  font-size: 18px;
}

.token_address_txt {
  color: var(--button_color);
  font-size: 15px;
  font-weight: bold;
}

.read_more_txt {
  color: var(--button_color);
  font-size: initial;
  font-weight: bold;
}

.checkbox {
  width: 35px;
  margin-right: 30px;
}

.contract-link {
  color: var(--button_color);
  font-weight: bold;
  cursor: pointer;
}

.redirect-logo {
  margin-left: 7px;
  margin-bottom: 2px;
}

.top {
  border-radius: 15px;
}

.see_sale_detail_card_whitepaper,
.see_sale_detail_card_login {
  position: relative;
  background-color: var(--button_color);
  color: var(--button_txt_color);
  border: 1px solid var(--button_border_color);
  height: 45px;
  border-radius: 40px;
  display: grid;
  text-align: center;
  align-content: center;
  padding: 0 25px;
  cursor: pointer;
}

.pr0 {
  padding-right: 0;
}

@media (max-width: 767px) {
  .see_sale_detail_card_container_info {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .see_sale_detail_card_buy_container {
    padding-top: 10px;
  }
}
